import React, { useState } from "react"
import { graphql, Link } from "gatsby"

import { Button } from "../components/button"
import { unsubscribe } from "../api"
import Seo from "../components/seo"

type Status = "sending" | "sent" | undefined

export default function UnsubscribePage({ data }) {
  const title = data.site.siteMetadata.title
  const [email, setEmail] = useState("")
  const [error, setError] = useState()
  const [status, setStatus] = useState<Status>()

  const loading = status === "sending"
  const sent = status === "sent"

  return (
    <>
      <Seo title="Unsubscribe" />
      <div className="global-wrapper">
        <header className="global-header">
          <Link className="header-link-home" to="/">
            {title}
          </Link>
          <h1>Unsubscribe</h1>
        </header>
        {sent ? (
          "Unsubscribed"
        ) : (
          <main>
            Fill the form to unsubscribe from our mailing list.
            <form
              className="news__form"
              onSubmit={async e => {
                e.preventDefault()
                setError(undefined)

                try {
                  setStatus("sending")
                  const { status } = await unsubscribe({ email })
                  if (status === 200) {
                    setStatus("sent")
                  } else {
                    throw new Error(`status ${status}`)
                  }
                } catch (error) {
                  setError(error.message ?? error)
                  setStatus(undefined)
                }
              }}
            >
              <div className="news__email">
                <>
                  <input
                    style={{ flex: 5 }}
                    required
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Enter your email address"
                  />
                  <Button style={{ flex: 2 }} loading={loading}>
                    Unubscribe
                  </Button>
                </>
              </div>
              {error ? (
                <div className="error-message">
                  Oops, there was an error. Please try again.
                </div>
              ) : null}
            </form>
          </main>
        )}
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
